

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: rgb(171, 142, 102);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;










const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;



const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function HairLossfaq({setIsModalOpen}) {
    return (
        <Section py="4" id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          <Container className="pb-md-5 mb pt-md-5">
            <Row className="justify-content-center">
              <Col lg="12">

                <Iwrap>


                  <Itext as="h2">Questions about Hair Loss</Itext>
                </Iwrap>
                <SecondText>
                  Frequently Asked Questions

                </SecondText>

              </Col>
            </Row>


            <Row className="">

              <Col

              >
                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What causes hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Hair loss can be caused by a variety of factors, including genetics, hormonal changes, medical
                        conditions, medications, and lifestyle factors such as stress and diet.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <br />

                <Accordion allowZeroExpanded classname="">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How do I know if I'm experiencing hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Signs of hair loss may include increased shedding, noticeable thinning of the hair, receding
                        hairline, or bald patches on the scalp. If you're concerned about hair loss, it's best to
                        consult with a qualified hair restoration specialist for an evaluation.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <br />

                <Accordion allowZeroExpanded classname="">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Are there effective treatments for hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, there are several effective treatments available for hair loss, ranging from topical
                        medications and oral supplements to advanced therapies such as PRP and Exosomes treatments. The
                        most suitable treatment will depend on the cause and severity of your hair loss.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <br />

                <Accordion allowZeroExpanded classname="">
                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What is PRP therapy for hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        PRP (Platelet-Rich Plasma) therapy is a non-surgical treatment that uses the patient's own blood
                        to stimulate hair follicle growth and promote hair regrowth. It involves extracting platelets
                        from the patient's blood and injecting them into the scalp to rejuvenate hair follicles.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <br />

                <Accordion allowZeroExpanded classname="">

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        What is PRP therapy for hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        PRP (Platelet-Rich Plasma) therapy is a non-surgical treatment that uses the patient's own blood
                        to stimulate hair follicle growth and promote hair regrowth. It involves extracting platelets
                        from the patient's blood and injecting them into the scalp to rejuvenate hair follicles.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How does Exosomes therapy work for hair loss?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exosomes therapy involves the use of tiny vesicles containing growth factors and proteins to
                        stimulate hair follicle regeneration and promote hair growth. These exosome particles are
                        derived from stem cells and play a crucial role in cellular communication and tissue repair.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Is hair loss treatment suitable for both men and women?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Yes, hair loss treatments are available for both men and women. However, the underlying causes
                        of hair loss may differ between genders, so it's important to consult with a specialist to
                        determine the most appropriate treatment plan for your specific needs.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Are hair loss treatments painful?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Most hair loss treatments, including PRP and Exosomes therapy, are minimally invasive and
                        typically involve minimal discomfort. Patients may experience some mild soreness or discomfort
                        at the injection site, but this is usually well-tolerated and resolves quickly.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        How long do the results of hair loss treatments last?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        The duration of results can vary depending on the type of treatment and individual factors. With
                        PRP and Exosomes therapy, results are typically long-lasting, but maintenance sessions may be
                        recommended to sustain optimal results over time.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <br />

                  <AccordionItem>
                    <AccordionItemHeading>
                       <AccordionItemButton className="text-[14px] pl-2">
                        Can I combine different hair loss treatments for better results?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Depending on your unique needs and goals, your hair restoration specialist may recommend
                        combining different treatments to maximize results. Combining therapies such as PRP and Exosomes
                        treatment can synergistically enhance hair growth and improve overall outcomes.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>


              </Col>


            </Row>


            <br /> <br />
            <div onClick={() => setIsModalOpen(true)}
                 activeClassName="active">
              <Bookbutton>Book appointment</Bookbutton>
            </div>

          </Container>
        </Section>


    );
}

